import { render, staticRenderFns } from "./Prices.vue?vue&type=template&id=272bc02a&scoped=true"
import script from "./Prices.vue?vue&type=script&lang=js"
export * from "./Prices.vue?vue&type=script&lang=js"
import style0 from "./Prices.vue?vue&type=style&index=0&id=272bc02a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "272bc02a",
  null
  
)

export default component.exports