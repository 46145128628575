<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container px-4">
        <h2 class="title is-3">Unsere Produkte in der Preisübersicht</h2>
        <div class="columns is-multiline is-centered mt-4">
          <div class="column is-one-third-desktop is-half-tablet">
            <div class="box">
              <h3 class="title is-size-5 is-size-6-touch mt-3">
                Website-Basic
              </h3>
              <div class="columns mt-3 is-mobile">
                <div class="column">
                  <div class="title is-size-2 is-size-3-touch has-text-primary">
                    500 €
                  </div>
                  <div
                    class="
                      subtitle
                      is-size-5 is-size-6-touch
                      has-text-secondary
                    "
                  >
                    einmalig
                  </div>
                </div>
                <div class="column">
                  <div class="title is-size-2 is-size-3-touch has-text-primary">
                    0 €
                  </div>
                  <div
                    class="
                      subtitle
                      is-size-5 is-size-6-touch
                      has-text-secondary
                    "
                  >
                    pro Monat
                  </div>
                </div>
              </div>

              <div class="divider"></div>
              <div class="p-4">
                <ul class="card__list">
                  <li class="card__list-item">
                    <font-awesome-icon
                      :icon="['fas', 'check']"
                      size="lg"
                      class="card__list-icon mr-3"
                    />
                    <p>Vorgespräch (bis 90 Minuten)</p>
                  </li>
                  <li class="card__list-item">
                    <font-awesome-icon
                      :icon="['fas', 'check']"
                      size="lg"
                      class="card__list-icon mr-3"
                    />
                    <p>Design</p>
                  </li>
                  <li class="card__list-item">
                    <font-awesome-icon
                      :icon="['fas', 'check']"
                      size="lg"
                      class="card__list-icon mr-3"
                    />
                    <p>3 x statische Seiten</p>
                  </li>
                  <li class="card__list-item">
                    <font-awesome-icon
                      :icon="['fas', 'check']"
                      size="lg"
                      class="card__list-icon mr-3"
                    />
                    <p>Impressum & Datenschutzerklärung</p>
                  </li>
                  <li class="card__list-item">
                    <font-awesome-icon
                      :icon="['fas', 'check']"
                      size="lg"
                      class="card__list-icon mr-3"
                    />
                    <p>Kontakt</p>
                  </li>
                  <li class="card__list-item">
                    <font-awesome-icon
                      :icon="['fas', 'check']"
                      size="lg"
                      class="card__list-icon mr-3"
                    />
                    <p>je 2 inkludierte Reviews & Änderungen</p>
                  </li>
                </ul>
              </div>
              <div class="divider"></div>
              <b-button
                type="is-primary"
                class="mt-3"
                tag="router-link"
                to="/contact"
                aria-roledescription="Website Basics"
                >Anfrage stellen</b-button
              >
            </div>
          </div>

          <div class="column is-one-third-desktop is-half-tablet">
            <div class="box">
              <h3 class="title is-size-5 is-size-6-touch mt-3">
                Website + Hosting
              </h3>
              <div class="columns mt-2">
                <div class="column">
                  <div class="title is-size-2 is-size-3-touch has-text-primary">
                    500 €
                  </div>
                  <div
                    class="
                      subtitle
                      is-size-5 is-size-6-touch
                      has-text-secondary
                    "
                  >
                    einmalig
                  </div>
                </div>
                <div class="column">
                  <div class="title is-size-2 is-size-3-touch has-text-primary">
                    25 €
                  </div>
                  <div
                    class="
                      subtitle
                      is-size-5 is-size-6-touch
                      has-text-secondary
                    "
                  >
                    pro Monat
                  </div>
                </div>
              </div>
              <div class="divider"></div>

              <div class="p-4">
                <ul class="card__list">
                  <li class="card__list-item">
                    <font-awesome-icon
                      :icon="['fas', 'check']"
                      size="lg"
                      class="card__list-icon mr-3"
                    />
                    <p>
                      Paket&nbsp;<span class="has-text-weight-bold"
                        >Website-Basic</span
                      >
                    </p>
                  </li>

                  <li class="card__list-item">
                    <font-awesome-icon
                      :icon="['fas', 'check']"
                      size="lg"
                      class="card__list-icon mr-3"
                    />
                    <p>SSL-Zertifikat</p>
                  </li>
                  <li class="card__list-item">
                    <font-awesome-icon
                      :icon="['fas', 'check']"
                      size="lg"
                      class="card__list-icon mr-3"
                    />
                    <p>Web-Hosting</p>
                  </li>
                  <li class="card__list-item">
                    <font-awesome-icon
                      :icon="['fas', 'check']"
                      size="lg"
                      class="card__list-icon mr-3"
                    />
                    <p>hohe Verfügbarkeit</p>
                  </li>
                  <li class="card__list-item">
                    <font-awesome-icon
                      :icon="['fas', 'check']"
                      size="lg"
                      class="card__list-icon mr-3"
                    />
                    <p>15 Änderungen p. A.</p>
                  </li>
                  <li class="card__list-item">
                    <font-awesome-icon
                      :icon="['fas', 'times-circle']"
                      size="lg"
                      class="card__list-icon item-excluded mr-3"
                    />
                    <p>ohne zzgl. Domainkosten</p>
                  </li>
                </ul>
              </div>

              <div class="divider"></div>
              <b-button
                type="is-primary"
                class="mt-3"
                tag="router-link"
                to="/contact"
                aria-roledescription="Website und Hosting"
                >Anfrage stellen</b-button
              >
            </div>
          </div>

          <div class="mt-6">
            <p class="title is-5 is-6-touch">
              Kontaktieren Sie uns gerne über unser
              <router-link to="/contact">Kontaktformular</router-link>, falls
              Sie ein Anliegen haben, welches unser Angebot übersteigt.
            </p>
            <p class="is-size-7 has-text-grey">
              Alle Preise exklusive Mehrwertsteuer.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Prices",
};
</script>


<style scoped>
.box {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.p-4 {
  height: 100%;
}
.divider {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.card__list {
  list-style-type: none;
}

.card__list-item {
  display: flex;
  align-items: center;
}

.card__list-item:not(:last-child) {
  margin-bottom: 0.75rem;
}

.card__list-item p {
  display: flex;
  text-align: left;
}

.card__list-icon {
  color: #7957d5;
}

.item-excluded {
  color: grey;
}
</style>
